import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-consulter-fiche-reception',
  templateUrl: './consulter-fiche-reception.component.html',
  styleUrls: ['./consulter-fiche-reception.component.scss']
})
export class ConsulterFicheReceptionComponent {

  @Input() public fiche;

  constructor(private http: HttpClient) {
  }

  telecharger(fiche): Subscription {
    return this.http.get(environment.url + 'download-fiche-reception/' + fiche.id,
        {observe: 'response', responseType: 'blob'})
        .subscribe((data: any) => {
          const extension = fiche.fiche?.extension;
          saveAs(data.body, 'fiche-reception.' + extension);
        });
  }
}
