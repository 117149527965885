<app-breadcrumb [title]="'Liste des fiches de reception'" [items]="['Fiche de reception']"
                [active_item]="'Liste des fiches de reception'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="signal-table">
                    <p-table [styleClass]="'table table-hover'"
                             id="myTable"
                             #dt1
                             [value]="items$"
                             [paginator]="true"
                             [rows]="10"
                             [showCurrentPageReport]="true"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             [rowsPerPageOptions]="[10, 25, 50]"
                             [globalFilterFields]="['intitule', 'objet', 'date', 'user']"
                    >
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <button type="button"
                                        style="height: 40px; border-radius: 50%; color: white"
                                        (click)="clear(dt1)" ngbtooltip="btn btn-square btn-info"
                                        class="btn btn-info btn-sm">
                                    <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
                                </button>

                                <span class="p-input-icon-left ml-auto">
                                    <input type="text" placeholder="Recherche..."
                                           class="form-control"
                                           (input)="dt1.filterGlobal($event.target.value, 'contains')"/>
                                </span>

                                <button type="button" ngbtooltip="btn btn-square btn-info"
                                        style="color: white; float: right;"
                                        [routerLink]="['/fiche-reception/form']"
                                        class="btn btn-info btn-sm">
                                    Ajouter une fiche de reception
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="background: white">Libellé du projet</th>
                                <th style="background: white">Objet</th>
                                <th style="background: white">Date</th>
                                <th style="background: white">Créé par</th>
                                <th style="background: white">Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr style="border-color: #dee2e6">
                                <td>{{ item.intitule }}</td>
                                <td>{{ item.objet }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.user }}</td>
                                <td>
                                    <div class="action">
                                        <div class="col text-end">
                                            <div ngbDropdown class="dropdown">
                                                <button class="btn dropdown-toggle"
                                                        id="dropdownMenuButton"
                                                        ngbDropdownToggle>
                                                    Actions
                                                </button>
                                                <div ngbDropdownMenu
                                                     aria-labelledby="dropdownMenuButton"
                                                     class="email-option-position">
                                                    <a href="javascript:void(0)"
                                                       class="dropdown-item"
                                                       [routerLink]="['/fiche-reception/update/'+ item.id]"
                                                    >
                                                        Modifier
                                                    </a>
                                                    <a href="javascript:void(0)"
                                                       (click)="consulter(item)"
                                                       class="dropdown-item">
                                                        Consulter
                                                    </a>
                                                    <a href="javascript:void(0)"
                                                       (click)="supprimer(item)"
                                                       class="dropdown-item">
                                                        Supprimer
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">
                                    <app-no-data></app-no-data>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
