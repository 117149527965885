import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FormulaireDocumentComponent} from './formulaire-document/formulaire-document.component';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-acte-document',
  templateUrl: './acte-document.component.html',
  styleUrls: ['./acte-document.component.scss']
})
export class ActeDocumentComponent implements OnInit {

  @Input() client: boolean;
  @Input() modelId: any;
  documents: any[] = [];

  constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.listerDocuments();
  }

  creer(): void {
    const modalRef = this.modalService.open(FormulaireDocumentComponent, {size: 'lg'});
    modalRef.componentInstance.client = this.client;
    modalRef.componentInstance.modelId = this.modelId;
    modalRef.result.then((result) => {
      if (result) {
        this.listerDocuments();
      }
    });
  }

  private listerDocuments(): void {
    if (this.client) {
      this.recupererDocuments('client-document/' + this.modelId);
    } else {
      this.recupererDocuments('acte-document/' + this.modelId);
    }
  }

  private recupererDocuments(url: string): void {
    this.http.get(environment.url + url)
        .subscribe((data: any) => {
          this.documents = data.data;
        });
  }

  supprimer(id: any): void {
    Swal.fire({
      title: 'Voulez vous vraiment effectuer cette action ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non !'
    }).then(result => {
      if (result.value) {
        this.http.get(environment.url + 'supprimer-document/' + id)
            .subscribe({
              next: () => {
                this.toastr.success('Document supprimé avec succès !', 'Suppression de document');
                this.listerDocuments();
              }
            });
      }
    });
  }
}
