import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UtilisateurRoutingModule} from './utilisateur-routing.module';
import {UtilisateurComponent} from './utilisateur.component';
import {SharedModule} from '../../shared/shared.module';
import {TableModule} from 'primeng/table';
import {FormulaireUtilisateurComponent} from './formulaire-utilisateur/formulaire-utilisateur.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgDropzoneComponent} from '../../components/ng-dropzone/ng-dropzone.component';


@NgModule({
  declarations: [
    UtilisateurComponent,
    FormulaireUtilisateurComponent,
    NgDropzoneComponent
  ],
  exports: [
    NgDropzoneComponent
  ],
  imports: [
    CommonModule,
    UtilisateurRoutingModule,
    SharedModule,
    TableModule,
    NgSelectModule,
    NgxDropzoneModule
  ]
})
export class UtilisateurModule {
}
