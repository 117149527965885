<div class="modal-header">
    <h5 class="modal-title" id="modal-title">
        Detail de la fiche de reception
    </h5>
</div>
<div class="modal-body p-4">
    <div class="col-lg-12">
        <div class="card">
            <div class="media">
                <div class="media-body">
                    <h6 class="f-w-600">
                        {{ fiche.objet }}
                    </h6>
                </div>
            </div>
            <div class="job-search">
                <div>
                    <div class="job-description">
                        <h6>Date et heure</h6>
                        <p>
                            {{ fiche.date }} de {{ fiche.heureDebut }} à {{ fiche.heureFin }}
                        </p>
                    </div>
                    <div class="job-description" *ngIf="fiche.intitule != ''">
                        <h6>Libellé du projet</h6>
                        <p [innerHTML]="fiche.intitule"></p>
                    </div>
                    <div class="job-description" *ngIf="fiche.participant != null">
                        <h6>Participants</h6>
                        <p [innerHTML]="fiche.participant"></p>
                    </div>
                    <div class="job-description" *ngIf="fiche.description != null">
                        <h6>Description</h6>
                        <p [innerHTML]="fiche.description"></p>
                    </div>
                    <div class="job-description">
                        <h6>Créé par </h6>
                        <p>{{ fiche.user }}</p>
                    </div>
                    <div class="job-description" *ngIf="fiche.fiche != null">
                        <button class="btn btn-primary me-1" type="button"
                                (click)="telecharger(fiche)">
                            <span>
                                <i class="fa fa-download"></i>
                            </span>
                            Télécharger la fiche
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
