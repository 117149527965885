import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Utilitaire {

  constructor(
      private datePipe: DatePipe
  ) {
  }

  public convertirVersFormData(data: any): FormData {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key] != null ? data[key] : '');
    }
    return formData;
  }

  public ajouterFichier(formData, libelle, fichiers: any = []): void {
    if (fichiers.length > 0) {
      const fichier = fichiers[0];
      formData.append(libelle, fichier);
    } else {
      formData.append(libelle, '');
    }
  }

  public dateFromString(date: any): {} {
    const annee = Number(this.datePipe.transform(date, 'y'));
    const mois = Number(this.datePipe.transform(date, 'M'));
    const jours = Number(this.datePipe.transform(date, 'd'));
    return {year: annee, month: mois, day: jours};
  }

  anotherDateFromString(date: any): {} {
    return {year: Number(date.year), month: Number(date.month), day: Number(date.day)};
  }

  public createDate(formData: any, data: any, libelle: any): void {
    const value = data.value;
    if (value instanceof Object) {
      formData.append(libelle, value.year + '/' + value.month + '/' + value.day);
    }
  }

  questionsReponses(): {} {
    return [
      {id: 0, libelle: 'Non'},
      {id: 1, libelle: 'Oui'}
    ];
  }

  listerSituationMatrimoniale(): {} {
    return [
      {id: 1, libelle: 'Celibataire'},
      {id: 2, libelle: 'Marié'},
      {id: 3, libelle: 'Veuf'},
      {id: 4, libelle: 'Divorce'},
      {id: 5, libelle: 'AyantDroits'}
    ];
  }

  listerRegimeMatrimoniale(): {} {
    return [
      {id: 1, libelle: 'Communauté'},
      {id: 2, libelle: 'Séparation'}
    ];
  }

  typeSociete(): {} {
    return [
      {id: 1, libelle: 'Société Commerciale'},
      {id: 2, libelle: 'Société Civile'}
    ];
  }
}
